import styled from "@emotion/styled";

import { Clickable } from "./buttons";

const TitleWrapper = styled.div<{ size: number; opacity: number }>`
  display: flex;
  flex-flow: row;
  align-items: center;

  padding: ${(props) => props.size}rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.disabled.base};
  opacity: ${(props) => props.opacity};

  .image {
    flex: 0 0 6rem;
    border-radius: 2px;
    height: 6rem;
    overflow: hidden;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .text {
    flex: 1;
    margin: 0 0 0 2rem;
    overflow: hidden;

    h1 {
      font-size: ${(props) => props.size * 3}rem;
      font-weight: bold;
      margin: 0.6rem 0;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h2 {
      font-size: ${(props) => props.size * 2}rem;
      font-weight: 600;
      margin: 0.4rem 0;
      color: ${(props) => props.theme.palette.foreground.accent};

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

type TitleProps = {
  top?: JSX.Element;
  text?: string;
  sub?: string;
  left?: JSX.Element;
  right?: JSX.Element;
  size?: number;
  opacity?: number;
  onClick?: () => void;
  label?: string;
};

const Title = ({
  top,
  text,
  sub,
  left,
  right,
  size,
  opacity,
  onClick,
  label,
}: TitleProps) => (
  <TitleWrapper size={size || 1} opacity={opacity || 1} aria-label={label}>
    {left}
    <Clickable className="text" onClick={onClick}>
      {top}
      {text && <h1>{text}</h1>}
      {sub && <h2>{sub}</h2>}
    </Clickable>
    {right}
  </TitleWrapper>
);

export { Title, TitleProps };
