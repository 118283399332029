import Fuse from "fuse.js";
import { useRef, useMemo, useEffect, useState } from "react";

import { DotNotation } from "@smart/itops-utils-basic";

export type FuseOptions<I extends {}> = {
  list: I[];
  keys: (DotNotation<I> | string)[];
};

export const useFuse = <I extends {}>({ list, keys }: FuseOptions<I>) => {
  const [search, setSearch] = useState("");
  const fuse = useRef(new Fuse(list, { keys, threshold: 0.4 }));

  useEffect(() => {
    fuse.current.setCollection(list);
  }, [list]);

  useEffect(() => {
    fuse.current.getIndex().setKeys(keys as string[]);
  }, [keys]);

  const results = useMemo<I[]>(
    () => (search ? fuse.current.search(search).map(({ item }) => item) : list),
    [search, list],
  );

  return {
    search,
    setSearch,
    results,
  };
};
