import styled from "@emotion/styled";

const PillRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  padding: 1rem;

  .none {
    color: ${(props) => props.theme.palette.disabled.base};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: bold;
    margin: 0 1rem;
  }
`;

const PillWrapper = styled.button<{ selected?: boolean }>`
  margin: 1rem;
  padding: 0.9rem 1.4rem 1.1rem;
  background: none;
  border-radius: 100px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) =>
    props.selected
      ? props.theme.palette.primary.base
      : props.theme.palette.background.base};
  border: 1px solid ${(props) => props.theme.palette.primary.base};
  color: ${(props) =>
    props.selected
      ? props.theme.palette.background.base
      : props.theme.palette.primary.base};

  p {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: bold;
  }
`;

type PillProps = {
  selected?: boolean;
  text: string;
  onClick?: () => void;
};

const Pill = ({ text, ...props }: PillProps) => (
  <PillWrapper {...props} type="button">
    <p>{text}</p>
  </PillWrapper>
);

export { PillProps, Pill, PillRow };
