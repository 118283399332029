import styled from "@emotion/styled";
import { Fragment, useMemo, useReducer } from "react";

import { useFuse } from "@smart/itops-hooks-dom";
import { IconName } from "@smart/itops-icons-dom";
import { formatTime, specialChars } from "@smart/itops-utils-basic";

import { IconButton } from "./buttons";
import { Input, Label } from "./fields";

const ListTableWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  overflow: hidden;

  .actions {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
  }

  .table {
    position: relative;
    overflow-y: auto;

    table {
      margin: 0 1rem 1rem;
      width: 98%;

      thead {
        position: sticky;
        top: 0;
        background: ${(props) => props.theme.palette.background.base};

        th {
          border-bottom: 1px solid
            ${(props) => props.theme.palette.foreground.accent};
          padding: 1rem;
          font-size: ${(props) => props.theme.fontSize.base};
          font-weight: bold;
          text-align: left;
        }
      }

      tbody {
        td {
          padding: 1rem;
          font-size: ${(props) => props.theme.fontSize.small};
          word-break: break-all;
          vertical-align: top;

          dl {
            display: grid;
            grid-template-columns: max-content auto;
            margin: 0;

            dt,
            dd {
              border-bottom: 1px dotted
                ${(props) => props.theme.palette.foreground.accent};
              padding: 0.4rem 0.5rem 0.6rem;
            }

            dt {
              font-weight: bold;
            }
            dd {
              margin: 0;
            }
          }
        }
      }
    }
  }
`;

type ListCellProps = {
  item: Record<string, any>;
  column: string;
};

const cellRenderer = (column: string, item: unknown) => {
  if (
    typeof item === "object" &&
    !!item &&
    (item as { type: string }).type === "Buffer"
  ) {
    return "Buffer";
  }
  if (typeof item === "object" && !!item)
    return (
      <dl>
        {Object.entries(item).map(([key, value]) => (
          <Fragment key={key}>
            <dt>{key}</dt>
            <dd>{cellRenderer(key, value)}</dd>
          </Fragment>
        ))}
      </dl>
    );
  if (column.endsWith("At"))
    return formatTime(new Date(item as string | number));
  if (typeof item === "string") return item;

  return `${item}`;
};

const ListCell = ({ item, column }: ListCellProps) => (
  <td>{cellRenderer(column, item[column])}</td>
);

type ListTableProps<I extends {}, K extends keyof I> = {
  plural: string;
  items: I[];
  keyField: K;
  columns: string[];
  searchColumns: string[];
  refresh?: () => void;
  actions?: [string, IconName, (keys: string[]) => void][];
};

const ListTable = <I extends {}, K extends keyof I>({
  plural,
  items,
  keyField,
  columns,
  searchColumns,
  refresh,
  actions,
}: ListTableProps<I, K>) => {
  const [selected, toggle] = useReducer(
    (state: Record<string, boolean>, key: string) => ({
      ...state,
      [key]: !state[key],
    }),
    {},
  );
  const keys = useMemo(
    () =>
      Object.entries(selected)
        .filter(([, v]) => !!v)
        .map(([k]) => k),
    [selected],
  );

  const { results, search, setSearch } = useFuse({
    keys: searchColumns,
    list: items,
  });

  return (
    <ListTableWrapper>
      <div className="actions">
        {refresh && <IconButton name="refresh" onClick={refresh} />}
        <Label
          name="search"
          text={`Search ${search && `${results.length} / `}${
            items.length
          } ${plural}`}
        >
          <Input
            name="search"
            type="search"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </Label>
        {actions?.map(([action, icon, handler]) => (
          <IconButton
            key={action}
            name={icon}
            disabled={!keys.length}
            text={
              keys.length
                ? `${action}${specialChars.nbsp}${keys.length}`
                : action
            }
            onClick={() => handler(keys)}
          />
        ))}
      </div>
      <div className="table">
        <table>
          <thead>
            <tr>
              {actions?.length && <th aria-label="Selected" />}
              {columns.map((column) => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {results.map((item) => (
              <tr
                key={`${item[keyField]}`}
                onClick={() => toggle(`${item[keyField]}`)}
              >
                {actions?.length && (
                  <td aria-label="Selected">
                    <input
                      type="checkbox"
                      checked={!!selected[`${item[keyField]}`]}
                      onChange={() => toggle(`${item[keyField]}`)}
                    />
                  </td>
                )}
                {columns.map((column) => (
                  <ListCell key={column} item={item} column={column} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ListTableWrapper>
  );
};

export { ListTable, ListTableProps };
