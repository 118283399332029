import { ApolloError, NetworkStatus } from "@apollo/client";

import { networkStatusToStatus, Status } from "@smart/itops-hooks-dom";
import { buildErrorCode, checkErrorCode } from "@smart/itops-utils-basic";

import { Loading } from "./loading";
import { Overlay } from "./overlay";

type StatusOverlayProps = {
  status: Status;
  errorCode?: string;
  description?: string;
};

const StatusOverlay = ({
  status,
  description,
  errorCode,
}: StatusOverlayProps) => {
  switch (status) {
    case "loading":
      return <Loading description={description} />;
    case "error": {
      const error = checkErrorCode(errorCode);
      return (
        <Overlay
          icon={error.level === "WARN" ? "warning" : "warning"}
          subHeading={error.message}
          title={error.code}
          level={error.level}
        />
      );
    }
    default:
      return null;
  }
};

type GqlStatusOverlayProps = {
  error?: ApolloError;
  networkStatus?: NetworkStatus;
  called?: boolean;
  loading?: boolean;
};

const GqlStatusOverlay = ({
  error,
  networkStatus,
  called,
  loading,
}: GqlStatusOverlayProps) => {
  const status = networkStatusToStatus(networkStatus, {
    error,
    called,
    loading,
  });
  const errorCode = buildErrorCode(error);

  return <StatusOverlay status={status} errorCode={errorCode} />;
};

export {
  StatusOverlayProps,
  StatusOverlay,
  GqlStatusOverlayProps,
  GqlStatusOverlay,
};
