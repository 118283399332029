import styled from "@emotion/styled";
import { transparentize } from "polished";
import { useState } from "react";

type OptionsButtonProps<O extends string> = {
  options: readonly O[];
  selected: O;
  select: (option: O) => void;
  images: Record<O, string>;
};

const OptionsWrapper = styled.div<{ dropped: boolean }>`
  position: relative;
  margin: 0 1rem;

  .main {
    background: none;
    border: 0;
    margin: 0;
    padding: 0.3rem;

    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .arrow {
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-top-color: ${(props) => props.theme.palette.disabled.base};
      margin: 6px 0 0 3px;
    }
  }

  img {
    border-radius: 3rem;
    display: block;
    width: 3rem;
  }

  .list {
    position: absolute;
    z-index: 999;
    background: var(--background);
    border-radius: 0.4rem;
    box-shadow: 1px 1px 0.8rem 0.2rem
      ${(props) => transparentize(0.6, props.theme.palette.foreground.accent)};
    display: ${(props) => (props.dropped ? "block" : "none")};

    button {
      cursor: pointer;
      background: none;
      border: 0;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }
`;

const OptionsButton = <O extends string>({
  options,
  selected,
  select,
  images,
}: OptionsButtonProps<O>) => {
  const [dropped, setDropped] = useState(false);
  return (
    <OptionsWrapper dropped={dropped}>
      <button
        className="main"
        type="button"
        onClick={() => setDropped(!dropped)}
      >
        <img src={images[selected]} alt={selected} />
        <div className="arrow" />
      </button>
      <div className="list">
        {options.map((o) => (
          <button
            key={o}
            type="button"
            className={o === selected ? "selected" : ""}
            onClick={() => {
              select(o);
              setDropped(false);
            }}
          >
            <img src={images[o]} alt={o} />
          </button>
        ))}
      </div>
    </OptionsWrapper>
  );
};

export { OptionsButton, OptionsButtonProps };
