import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const inputStyle = (props: { theme: Theme }) => css`
  width: 100%;
  border: 0;
  font-size: ${props.theme.fontSize.base};
  padding: 1rem;
  margin: 0;
  background: none;

  &:disabled {
    color: ${props.theme.palette.disabled.base};
  }

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input<{ readOnly?: boolean }>`
  ${inputStyle}
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "text")};
`;
