import {
  formatDistance,
  formatRelative,
  differenceInDays,
  format,
} from "date-fns";

import { specialChars } from "./special";

const formatDistanceLocale: { [key: string]: string } = {
  aboutXHours: "{{count}}h",
  aboutXMonths: "{{count}}M",
  aboutXYears: "{{count}}y",
  almostXYears: "{{count}}y",
  halfAMinute: "30s",
  lessThanXMinutes: "{{count}}m",
  lessThanXSeconds: "{{count}}s",
  overXYears: "{{count}}y",
  xDays: "{{count}}d",
  xHours: "{{count}}h",
  xMinutes: "{{count}}m",
  xMonths: "{{count}}M",
  xSeconds: "{{count}}s",
  xYears: "{{count}}y",
};

export const locale = {
  formatDistance: (token: string, count: number) =>
    (formatDistanceLocale[token] || "").replace("{{count}}", `${count}`),
};

const formatRelativeCase = (
  relativeString: string,
  options?: { lowercase?: boolean },
) =>
  (options?.lowercase
    ? relativeString
    : `${relativeString.charAt(0).toLocaleUpperCase()}${relativeString.slice(
        1,
      )}`
  ).replace(/ [ap]m$/i, (m) => m.toLocaleLowerCase().trim());

export function formatTime(
  start: Date | number | string | undefined,
  options?: {
    end?: Date;
    short?: boolean;
    dateOnly?: boolean;
    lowercase?: boolean;
  },
) {
  if (!start) return specialChars.infinity;

  try {
    let startDate;
    if (typeof start === "string" && start.match(/^\d+$/)) {
      startDate = parseInt(start, 10);
    } else if (typeof start === "string") {
      startDate = new Date(start);
    } else {
      startDate = start;
    }

    const compare = (options && options.end) || Date.now();

    if (options && options.short) {
      return formatDistance(startDate, compare, { locale });
    }

    const days = differenceInDays(startDate, compare);
    if (days <= 6 && days >= -6) {
      const relative = formatRelative(startDate, compare);
      const relativeString = options?.dateOnly
        ? relative.replace(/ at.*/, "").trim()
        : relative;
      return formatRelativeCase(relativeString, options);
    }

    return options?.dateOnly
      ? format(startDate, "MMM d, y")
      : format(startDate, "MMM d, y 'at' h:mmaaaaa'm'");
  } catch (e) {
    console.error("Failed to format date", start);
    return specialChars.infinity;
  }
}
