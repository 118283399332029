import styled from "@emotion/styled";

export const InlineForm = styled.form`
  position: relative;
  margin: 1rem auto;
  max-width: 80rem;
  width: 98%;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  label {
    flex: 1;
    margin-right: 1rem;
  }
`;
