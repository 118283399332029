import styled from "@emotion/styled";

export const TagsWrapper = styled.p`
  margin: 0;
  .tag {
    color: ${(props) => props.theme.palette.foreground.accent};
    font-size: ${(props) => props.theme.fontSize.xSmall};
    font-weight: 600;

    :not(:last-child) {
      :after {
        content: "";
        border-right: 1px solid ${(props) => props.theme.palette.disabled.base};
        margin: 0 0.6rem -0.8rem;
        height: 2.4rem;
        display: inline-block;
      }
    }
  }
`;

type TagsProps = {
  tags: string[];
  titles: string[];
};

const Tags = ({ tags, titles }: TagsProps) => (
  <TagsWrapper>
    {tags.map((tag, i) => (
      <span key={tag || titles[i]} title={titles[i]} className="tag">
        {tag}
      </span>
    ))}
  </TagsWrapper>
);

export { TagsProps, Tags };
