import styled from "@emotion/styled";

export const Select = styled.select`
  border: 0;
  font-size: ${(props) => props.theme.fontSize.base};
  padding: 1rem;
  margin: 0;
  background: none;

  &.on-default {
    color: gray;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 1;
  }

  &:not(:focus):invalid {
    color: #888;
  }
`;
