import styled from "@emotion/styled";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

import { displayName } from "@smart/itops-utils-basic";

import { TitleProps, Title } from "./title";

export const Column = styled.div<{ slim?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: column;

  height: 100%;
  width: ${(props) => (props.slim ? props.theme.breakPoints.mobile : "100%")};
  max-width: 100%;
  margin: 0 auto;
`;

export const PageWrapper = styled.div`
  padding: 2rem;
  height: 100%;
  overflow: auto;
`;

export type PageProps = TitleProps & {
  children: ReactNode;
  className?: string;
};

export const Page = ({ children, className, ...props }: PageProps) => (
  <PageWrapper className={className}>
    <Helmet>
      <title>{[props.text, displayName].filter(Boolean).join(" | ")}</title>
    </Helmet>
    <Title {...props} />
    {children}
  </PageWrapper>
);
