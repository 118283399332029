import { useEffect, useState } from "react";

import { formatTime, specialChars } from "@smart/itops-utils-basic";

export const useTime = (
  t: Date | number | string | undefined,
  options?: { short?: true; lowercase?: boolean },
) => {
  const [display, setDisplay] = useState<string>();
  useEffect(() => {
    const set = () =>
      setDisplay(t ? formatTime(t, options) : specialChars.enDash);
    set();

    const interval = setInterval(set, 60 * 1000);
    return () => clearInterval(interval);
  }, [t]);

  return display;
};
