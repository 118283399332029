import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Spinner } from "../spinner";

const labelParts = (props: { theme: Theme }) => css`
  position: relative;
  width: 100%;
  cursor: default;
  margin: 1.8rem 0 1rem;

  select {
    width: 100%;
  }

  &[aria-errormessage],
  &[aria-invalid="true"] {
    border-color: ${props.theme.palette.danger.accent};
  }
  &:focus-within {
    border-color: ${props.theme.palette.primary.base};
  }
  &[aria-disabled="true"] {
    border-color: ${props.theme.palette.disabled.accent};
    cursor: not-allowed;
  }

  .row {
    display: flex;
    flex-flow: row;

    input,
    select {
      flex: 1;
      margin-right: 1rem;
    }
  }

  & > input:not(:last-of-type),
  & > select:not(:last-of-type),
  .row input,
  .row select {
    border-bottom: 1px dotted ${props.theme.palette.foreground.accent};
    margin-bottom: 0.2rem;
  }

  .spinner {
    position: absolute;
    top: 0.8rem;
    right: 1rem;
  }
`;

const LabelBox = styled.label<{
  hasTooltip: boolean;
  alwaysOn: boolean | undefined;
}>`
  display: block;
  padding: 0.4rem ${(props) => (props.hasTooltip ? "3rem" : "0.4rem")} 0.3rem
    0.4rem;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.palette.disabled.base};

  .spacer-text {
    font-size: ${(props) => props.theme.fontSize.xSmall};
    margin: -1.5rem 0 0 1.6rem;
    line-height: 1.2;
    padding: 0 0.4rem;
    visibility: hidden;
    white-space: nowrap;
    overflow: hidden;
  }

  .label-text {
    position: absolute;
    top: -0.8rem;
    left: 1.6rem;
    background: var(--background);
    width: fit-content;
    max-width: 90%;
    padding: 0 0.8rem;
    font-size: ${(props) => props.theme.fontSize.xSmall};
    margin: 0;
    opacity: ${(props) =>
      props["aria-placeholder"] && !props.alwaysOn ? 0 : 1};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    transition: opacity 0.2s linear;
  }

  textarea:not(:placeholder-shown) ~ .label-text,
  select:not(.on-default) ~ .label-text,
  &:has(input:not(:placeholder-shown)) .label-text {
    opacity: 1;
  }

  input:placeholder-shown,
  select:placeholder-shown {
    text-overflow: ellipsis;
  }

  .label-tooltip-close {
    font-size: 0; // Prevent font size from affecting the height
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
  }

  ${labelParts}
`;

const LabelOpen = styled.label`
  display: flex;
  flex-direction: column;

  * {
    order: 2;
  }

  .spacer-text {
    display: none;
  }

  .label-text {
    display: flex;
    align-items: center;
    order: 1;
    font-size: ${(props) => props.theme.fontSize.base};
    line-height: 1.5;
    font-weight: bold;

    margin: 2rem 0 1.2rem;
  }

  .label-tooltip-open {
    font-size: 0; // Prevent font size from affecting the height
    margin-left: 1rem;
  }

  ${labelParts}
`;

const ErrorWrapper = styled.p`
  font-size: ${(props) => props.theme.fontSize.xSmall};
  margin-top: 0;
  margin-left: 1.4rem;
  display: block;
  color: ${(props) => props.theme.palette.danger.base};
`;

type LabelProps = {
  children: ReactNode;
  className?: string;
  name: string;
  text?: string;
  error?: string;
  invalid?: boolean;
  loading?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
  open?: boolean;
  placeholder?: string;
  tooltip?: ReactNode;
  alwaysOn?: boolean;
};

export const Label = ({
  name,
  text,
  error,
  invalid,
  children,
  className,
  loading,
  disabled,
  mandatory,
  open,
  placeholder,
  tooltip,
  alwaysOn,
}: LabelProps) => {
  const LabelWrapper = open ? LabelOpen : LabelBox;
  const label = text ? `${text}${mandatory ? " *" : ""}` : "";

  return (
    <>
      <LabelWrapper
        className={className ? `${className} label` : "label"}
        aria-placeholder={placeholder}
        aria-disabled={disabled}
        aria-invalid={!!error || invalid}
        aria-errormessage={error && `${name}-error`}
        hasTooltip={!!tooltip}
        alwaysOn={alwaysOn}
        htmlFor={name}
      >
        <div className="spacer-text" aria-hidden="true">
          s{label}
        </div>
        {children}
        {loading && <Spinner size={3} className="spinner" />}
        <div title={label} className="label-text">
          {label}
          {tooltip && open && (
            <div className="label-tooltip-open">{tooltip}</div>
          )}
        </div>
        {tooltip && !open && (
          <div className="label-tooltip-close">{tooltip}</div>
        )}
      </LabelWrapper>
      {!disabled && error && (
        <ErrorWrapper id={`${name}-error`}>{error}</ErrorWrapper>
      )}
    </>
  );
};
